// `fbq` is defined globally in index.html.
// Consider that it might be unset e.g. by ad blockers.
declare const fbq: Function | undefined;

import { AppInfo } from '@capacitor/app';
import { DeviceInfo } from '@capacitor/device';
import mixpanel, { Dict } from 'mixpanel-browser';
import { Route } from 'vue-router';
import { experimentsState } from './boot/set-up-experiments';
import { Comment, CommunicationPrefs, CurrentUser, Investigation, Post, PostQueryParameters } from './types';
import { longAddressFromComponents } from './util.location';
import { addPropertiesToMoEngageSessionEvents, trackInMoEngage, updateMoEngageUser } from './util.moengage';

function setOnSessionEvents(properties: Dict) {
    mixpanel.register(properties);
    addPropertiesToMoEngageSessionEvents(properties);
}

async function trackEvent(eventName: string, properties?: Dict) {
    try {
        mixpanel.track(eventName, properties);
        await trackInMoEngage(eventName, properties);
    } catch (error) {
        console.error(error);
    }
}

function setOnUser(properties: Dict) {
    mixpanel.people.set(properties);
    updateMoEngageUser(properties);
}

function cleanFacebookEventParams(params: Record<string, unknown>) {
    const cleanParams = { ...params };
    delete cleanParams.lat;
    delete cleanParams.lng;
    delete cleanParams.lon;
    return cleanParams;
}

// Only specific events are sent to Facebook.
// TODO: Can we use `trackInFacebook for this` and not include the pixel script?
// Will that mess with Facebook sign-in?
function trackInGlobalFacebook(event: string, params: Record<string, string> = {}) {
    if (typeof fbq !== 'undefined') {
        const cleanParams = cleanFacebookEventParams(params);
        fbq('track', event, cleanParams);
    }
}

// https://developers.facebook.com/docs/meta-pixel/advanced#installing-the-pixel-using-an-img-tag
export async function trackInFacebook(pixelId: string, event: string, properties: Record<string, unknown> = {}): Promise<void> {
    const url = new URL('https://www.facebook.com/tr');

    url.searchParams.set('id', pixelId);
    url.searchParams.set('ev', event);

    const cleanParams = cleanFacebookEventParams(properties);
    for (const [key, value] of Object.entries(cleanParams)) {
        if (value !== null && value !== undefined) {
            url.searchParams.set(`cd[${key}]`, String(value));
        }
    }

    await fetch(url.href, { mode: 'no-cors' });
}

function getUserLocationProperties(user: CurrentUser) {
    if (!user.lng && !user.lat) return {};

    return {
        $longitude: user.lng,
        $latitude: user.lat,
        'Home Location': longAddressFromComponents(user.addressComponents) || '',
        'Home Neighborhood': user.addressComponents.neighborhood || '',
        'Home City': user.addressComponents.city || '',
        'Home Place': user.addressComponents.place || '',
        'Home District': user.addressComponents.district || '',
        'Home State': user.addressComponents.state || '',
        'Home State Code': user.addressComponents.stateCode || '',
        'Home ZIP': user.addressComponents.zipcode || '',
        'Home Country': user.addressComponents.country || '',
        'Home Country Code': user.addressComponents.countryCode || '',
    };
}

export function trackEnvironment(device: DeviceInfo, app?: AppInfo) {
    // Tracked automatically by MoEngage
    setOnSessionEvents({
        'Platform': device.platform,
        'OS Version': device.osVersion,
        'App Version': app?.version,
    });
}

export async function trackExperimentGroups() {
    return Object.entries(experimentsState).forEach(([key, value]) => {
        setOnSessionEvents({ [`"${key}" experiment group`]: value });
    });
}

export function trackLocale(locale: string, explicit = false) {
    if (explicit) {
        trackEvent('Locale Set', { 'Locale': locale });
    }

    setOnSessionEvents({ 'Locale': locale });
    setOnUser({ 'Locale': locale });
}

export function trackCommunicationPreferences(preferences: CommunicationPrefs) {
    const preferencesCopy: Partial<CommunicationPrefs> = { ...preferences };
    delete preferencesCopy.notifyMethod;
    setOnUser(preferencesCopy);
}

export function trackReferringPartner(partner: string) {
    setOnSessionEvents({
        'Referring Partner': partner,
    });
}

export function trackReferringSource(source: string) {
    setOnSessionEvents({
        'Referring Source': source,
    });
}

export function trackPageView(to: Route): void {
    trackEvent('Page View', {
        'Route Name': to.name,
        'Route Full Path': to.fullPath,
        'Route Query': JSON.stringify(to.query),
    });

    // Moengage is configured with `enableSPA`, so we won't call `Moengage.track_page_view`.
}

let accountCreationStartedAt: number;

export function trackSignUpPageView(): void {
    trackEvent('Sign-up Page View');
    mixpanel.time_event('Account Created');
    mixpanel.time_event('Profile Completed');

    accountCreationStartedAt = Date.now();
}

export function trackAppLinkClick(platform: string) {
    const which = ({
        android: 'Google Play store link',
        ios: 'Apple App Store link'
    } as any)[platform];

    if (which) {
        trackEvent('App Store Link Clicked', {
            'Platform': which,
        });

        trackInGlobalFacebook('ViewContent', { content_name: which });
    }
}

export function trackAccountCreation(user: CurrentUser): void {
    mixpanel.alias(user.id);
    mixpanel.people.set({
        $name: `${user.firstName} ${user.lastName}`,
        $email: user.email,
        $phone: user.mobilePhone,
    });

    updateMoEngageUser({
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        mobilePhone: user.mobilePhone,
    });

    trackEvent('Account Created', {
        'Seconds Since Sign-up Started': (Date.now() - accountCreationStartedAt) / 1000,
    });

    trackInGlobalFacebook('CompleteRegistration');
}

export function trackRegistrationLocation(user: CurrentUser): void {
    setOnUser(getUserLocationProperties(user));

    mixpanel.track('Profile Completed'); // Bad name, but already used in Mixpanel.
    trackEvent('Home Location Set', { // Better name!
        'Seconds Since Sign-up Started': (Date.now() - accountCreationStartedAt) / 1000,
    });

    trackInGlobalFacebook('FindLocation');
}

export function trackSignIn(user: CurrentUser, { service }: { service?: string } = {}): void {
    mixpanel.identify(user.id);
    updateMoEngageUser({ id: user.id });

    // Make sure we're in sync.

    mixpanel.people.set({
        $name: `${user.firstName} ${user.lastName}`,
        $email: user.email,
        $phone: user.mobilePhone,
    });

    updateMoEngageUser({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        mobilePhone: user.mobilePhone,
    });

    setOnUser({
        ...getUserLocationProperties(user),
        'Sign-in Service': service,
    });

    trackEvent('Signed In', {
        'Service': service,
    });
}

export function trackPresence(user: CurrentUser | null): void {
    if (user === null) {
        mixpanel.reset();
        updateMoEngageUser(null);
    } else {
        mixpanel.identify(user.id);
        updateMoEngageUser({ id: user.id });

        // Make sure we're in sync.

        mixpanel.people.set({
            $name: `${user.firstName} ${user.lastName}`,
            $email: user.email,
            $phone: user.mobilePhone,
        });

        updateMoEngageUser({
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            mobilePhone: user.mobilePhone,
        });

        setOnUser(getUserLocationProperties(user));
        trackCommunicationPreferences(user.communicationPreferences);
    }
}

export function trackSignOut(): void {
    trackEvent('Signed Out');

    mixpanel.reset();
    updateMoEngageUser(null);
}

export function trackProfileUpdate(user: CurrentUser): void {
    mixpanel.people.set({
        $name: `${user.firstName} ${user.lastName}`,
        $email: user.email,
        $phone: user.mobilePhone,
    });

    updateMoEngageUser({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        mobilePhone: user.mobilePhone,
    });

    setOnUser(getUserLocationProperties(user));

    trackEvent('Profile Updated');
}

export function trackCommentCreation(comment: Comment, post: Post, investigation?: Investigation | null): void {
    mixpanel.people.set_once('First Post Commented', new Date());
    setOnUser({ 'Last Post Commented': new Date() });
    mixpanel.people.increment('Total Post Comments');

    const properties = {
        'Post ID': post.id,
        'Comment ID': comment.id,
        'Body Length': comment.textBody.length,
    };

    if (investigation) {
        Object.assign(properties, {
            'Investigation Slug': investigation.slug,
        });

        mixpanel.people.union('Post Investigations Commented On', investigation.slug);
    }

    trackEvent('Comment Created', properties);
}

let sightingCreationStartedAt: number;

export function timePostCreation(): void {
    mixpanel.time_event('Sighting Created');
    sightingCreationStartedAt = Date.now();
}

export function trackPostCreation(post: Post, investigations: Investigation[], selectedMediaCount: number): void {
    mixpanel.people.set_once('First Posted', new Date());
    setOnUser({ 'Last Posted': new Date() });
    mixpanel.people.increment('Total Posts');

    const investigationSlugs = investigations.map(investigation => investigation.slug);

    const properties = {
        'Post ID': post.id,
        $longitude: post.lng,
        $latitude: post.lat,
        'Body Length': post.textBody.length,
        'Image Count': post.photos.length,
        'Image Count (As Selected)': selectedMediaCount, // This should match "Image Count". If it doesn't, something's wrong!
        'Investigations': investigationSlugs,
        'Seconds Since Sighting Creation Started': (Date.now() - sightingCreationStartedAt) / 1000,
    };

    mixpanel.people.union('Investigations Posted On', investigationSlugs);

    trackEvent('Sighting Created', properties);
}

export function trackPostView(postId: Post['id']) {
    trackEvent('Sighting Viewed', { 'Post ID': postId });
}

const postCreationStepsEnteredAt: { [step: string]: number } = {};

export function trackPostFormStepEnter(step: string) {
    trackEvent(`Post Creation Step "${step}" Entered`);
    mixpanel.time_event(`Post Creation Step "${step}" Exited`);
    postCreationStepsEnteredAt[step] = Date.now();
}

export function trackSuggestedTagRemoval(tag: string) {
    trackEvent(`Post Creation Suggested Tag Removed`, { 'Tag': tag });
}

export function trackPostFormStepExit(step: string) {
    trackEvent(`Post Creation Step "${step}" Exited`, {
        'Seconds Since Entered': (Date.now() - postCreationStepsEnteredAt[step]) / 1000,
    });
}

export function trackSightingsFeedFilter(button: string, query: PostQueryParameters) {
    trackEvent(`Sightings Feed Filtered`, { button, ...query });
}

export function trackActionsPageEvent(action: string, properties?: any) {
    trackEvent(action, properties);
}

export function trackHistoricalWeatherPromptBannerReveal(analysis: any) {
    trackEvent('Historical Weather Prompt Banner Revealed', analysis);
}

export function trackHistoricalWeatherPromptDismiss(analysis: any) {
    trackEvent('Historical Weather Prompt Dismissed', analysis);
}

export function trackHistoricalWeatherPromptOpen(analysis: any) {
    trackEvent('Historical Weather Prompt Opened', analysis);
}

export function trackHistoricalWeatherLearnMoreOpen(analysis: any) {
    trackEvent('Historical Weather Learn More Opened', analysis);
}

export function trackHistoricalWeatherPromptClickThrough(analysis: any) {
    trackEvent('Historical Weather Prompt Clicked Through', analysis);
}
