import store from '@/store';
import { getAuthUrl } from 'isc-shared/auth';
import type { NavigationGuard, Route } from 'vue-router';
import RouteNames from './names';

export async function redirectIfSessionRequired(to: Route) {
    if (to.meta?.mustBeSignedIn) {
        await store.state.account.currentSessionCheck;
        if (!store.state.account.currentUser) {
            const redirect = new URL(to.fullPath, location.origin);
            const authUrl = getAuthUrl(redirect.href);
            location.replace(authUrl);
        }
    }
}

export const clientGuard: NavigationGuard = async (to, from, next) => {
    await store.state.account.currentSessionCheck;
    const hasClientGroup = Boolean((store.state.account.currentUser?.clientGroups?.length ?? 0) > 0);

    if (hasClientGroup) {
        next();
    } else {
        next({ name: RouteNames.HOME });
    }
}

// const multipleGuards = (guards: NavigationGuard[]) => {
//     const multipleGuards: NavigationGuard = async (to, from, next) => {
//         let calledNextWithArgs = false;

//         function trackedNext(...args: Parameters<typeof next>) {
//             if (args.length !==0) {
//                 calledNextWithArgs = true;
//                 next(...args);
//             }
//         }

//         for (const guard of guards) {
//             await guard(to, from, trackedNext);
//             if (calledNextWithArgs) return;
//         }

//         next();
//     }

//     return multipleGuards;
// }
