import Vue from 'vue';

type ExperimentsState = {
    [Key in keyof typeof experiments]: (typeof experiments)[Key][number];
};

declare module 'vue/types/vue' {
    interface Vue {
        $experiments: ExperimentsState;
    }
}

/**
 * The keys here will be available in a component as e.g. `this.$experiments.thingToTest`
 * with its value being one of the items in the array. This is persisted in localStorage.
 * You can re-randomize everything with a `?reset-experiments` param.
 * You can maually choose an option with an `?experiments.thingToTest=versionOne` param.
 * See the ExperimentsMenu component for quick'n'dirty UI.
 * TODO: Add weighting; for now you can just add values to the array multiple times.
 */
export const experiments = {
    // thingToTest: ['versionOne', 'versionTwo'],
} as const;

export const experimentsState = Object.fromEntries(Object.keys(experiments).map(key => {
    const typedKey = key as keyof typeof experiments;
    return [typedKey, getExperimentGroup(typedKey)];
})) as ExperimentsState;

function getExperimentGroup<
    Key extends keyof typeof experiments,
    Val extends (typeof experiments)[Key][number],
>(key: Key): Val {
    const searchParams = new URLSearchParams(location.search);
    const resetAllExperiments = searchParams.has('reset-experiments');

    const possibleValues = experiments[key];
    const storageKey = `experiments.${key}`;
    const valueFromUrl = searchParams.get(storageKey);

    if (valueFromUrl && (possibleValues as unknown as unknown[]).includes(valueFromUrl)) {
        localStorage.setItem(storageKey, valueFromUrl);
    } else if (resetAllExperiments || valueFromUrl === '' || !localStorage.getItem(storageKey)) {
        const randomGroup = randomValueFrom(experiments[key]);
        if (typeof randomGroup === 'string') {
            localStorage.setItem(storageKey, randomGroup);
        }
    }

    const storedGroup = localStorage.getItem(storageKey);
    return storedGroup as Val;
}

function randomValueFrom<T>(array: Readonly<T[]>) {
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
}

export default function setUpExperiments() {
    Vue.use({
        install(VueConstructor) {
            VueConstructor.prototype.$experiments = experimentsState;
        },
    });
}
