import { i18n } from '@/i18n';
import Preview from '@/pages/Preview.vue';
import store from '@/store';
import { trackPageView, trackReferringSource } from '@/tracking';
import { Device } from '@capacitor/device';
import orderBy from 'lodash/orderBy';
import Vue from 'vue';
import Router from 'vue-router';
import clientRedirects from './client-redirects';
import externalRedirects from './external-redirects';
import RouteNames from './names';
import restoreQueryFromSettings from './restore-query-from-settings';
import { clientGuard, redirectIfSessionRequired } from './signed-in-guard';

Vue.use(Router);

const router =  new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition ?? { x: 0, y: 0 };
  },
  routes: [
    ...externalRedirects,
    ...clientRedirects,
    {
      // The Vue config has its own version of this that it builds for the web,
      // which is much faster for external apps to load because it doesn't load the entire app.
      // This one is for the apps, where everything's served locally anyway, so speed is less of an issue.
      name: 'oauth-helper',
      path: '/oauth',
      component: () => import(/* webpackChunkName: "helpers" */ '@/pages/OAuth/Index.vue'),
      meta: { isHelper: true },
    },
    {
      // This one's to allow embedding HubSpot/Typeform forms as surveys,
      // e.g. survey.settings.embed.url === '/embedded-form?region=$REGION&portalId=$PORTAL_ID&formId=$FORM_ID'
      name: 'embedded-form',
      path: '/embedded-form',
      component: () => import(/* webpackChunkName: "helpers" */ '@/pages/EmbeddedForm.vue'),
      meta: { isHelper: true },
    },
    {
      name: RouteNames.PARTNER_LANDING_PAGE,
      path: '/partners/:partner',
      component: () => import(/* webpackChunkName: "campaign" */ '@/pages/PartnerLandingPage.vue'),
      meta: {
        hideMainNavWithNoUser: true,
      },
    },
    {
      name: RouteNames.CAMPAIGN_LANDING_PAGE,
      path: '/campaigns/:campaign',
      component: () => import(/* webpackChunkName: "campaign" */ '@/pages/CampaignLandingPage.vue'),
      meta: {
          ignoreAppLayout: true,
      },
    },
    {
      path: '/',
      name: RouteNames.HOME,
      component: () => import('@/pages/Home.vue'),
      async beforeEnter(to, from, next) {
        const { platform } = await Device.getInfo();

        if (platform === 'web') {
          next();
        } else {
          next({ name: RouteNames.POSTS, query: to.query });
        }
      },
    },
    {
      path: '/preview',
      name: 'preview',
      component: Preview
    },
    {
      path: '/sign-up',
      name: RouteNames.REGISTER,
      component: () => import(/* webpackChunkName: "account" */ '@/pages/Registration/Index.vue'),
    },
    {
      path: '/forgot-password',
      name: RouteNames.FORGOT_PASSWORD,
      component: () => import(/* webpackChunkName: "account" */ '@/pages/AccountForgotPassword.vue'),
      meta: {
        hideMobileNav: true,
      },
    },
    {
      path: '/reset-password',
      name: RouteNames.RESET_PASSWORD,
      component: () => import(/* webpackChunkName: "account" */ '@/pages/AccountResetPassword.vue'),
      meta: {
        hideMobileNav: true,
      },
    },
    {
      path: '/log-in',
      name: RouteNames.LOGIN,
      component: () => import(/* webpackChunkName: "account" */ '@/pages/AccountLogIn.vue'),
    },
    {
      path: '/stories',
      name: RouteNames.STORIES,
      component: () => import(/* webpackChunkName: "content" */ '@/pages/Stories.vue'),
    },
    {
      path: '/map/:clientGroupSlug?',
      redirect: { name: 'client-map-view' },
    },
    {
      path: '/sightings',
      name: RouteNames.POSTS,
      component: () => import(/* webpackChunkName: "posts" */ '@/pages/Posts/index.vue'),
      beforeEnter: restoreQueryFromSettings('sightingsQuery'),
    },
    {
      path: '/observations',
      redirect: { name: RouteNames.POSTS },
    },
    {
      path: '/client/:clientGroupSlug?',
      name: 'client-home',
      beforeEnter: clientGuard,
      component: () => import(/* webpackChunkName: "client" */ '@/pages/Client/Layout.vue'),
      redirect: { name: 'client-map-view' },
      children: [{
        path: 'user-groups',
        name: 'client-user-groups-index',
        component: () => import(/* webpackChunkName: "client" */ '@/pages/Client/UserGroups/Index.vue'),
        meta: { baseRoute: 'client-map-view' },
      }, {
        path: 'user-groups/:userGroupId',
        name: 'client-user-group-details',
        component: () => import(/* webpackChunkName: "client" */ '@/pages/Client/UserGroups/Details.vue'),
        props: route => ({ userGroupId: route.params.userGroupId }),
        meta: { baseRoute: 'client-user-groups-index' },
      }, {
        path: 'surveys',
        name: 'client-surveys-index',
        component: () => import(/* webpackChunkName: "client" */ '@/pages/Client/Surveys/Index.vue'),
        meta: { baseRoute: 'client-map-view' },
      }, {
        path: 'surveys/:surveyId',
        name: 'client-survey-details',
        component: () => import(/* webpackChunkName: "client" */ '@/pages/Client/Surveys/Details.vue'),
        meta: { baseRoute: 'client-surveys-index' },
      }, {
        path: 'surveys/:surveyId/edit',
        name: 'client-edit-survey',
        component: () => import(/* webpackChunkName: "client" */ '@/pages/Client/Surveys/Edit.vue'),
        props: route => ({ surveyId: route.params.surveyId }),
        meta: { baseRoute: 'client-survey-details' },
      }, {
        path: 'people',
        name: RouteNames.PEOPLE,
        component: () => import(/* webpackChunkName: "client" */ '@/pages/Client/People/Index.vue'),
        meta: { baseRoute: 'client-map-view' },
      }, {
        path: '/map/:clientGroupSlug?',
        name: 'client-map-view',
        // component: () => import(/* webpackChunkName: "client" */ '@/pages/Client/Map/Index.vue'),
        component: () => import(/* webpackChunkName: "client" */ '@/pages/Client/Map/Interstitial.vue'),
      }],
    },
    {
      path: '/posts/create',
      name: RouteNames.POST_CREATE,
      component: () => import(/* webpackChunkName: "posts" */ '@/pages/PostsCreate/index.vue'),
      props: (route) => ({ investigationIdFromQuery: route.query.investigation }),
      meta: {
        mustBeSignedIn: true,
        baseRoute: RouteNames.POSTS,
        hideMobileNav: true,
        hideFooter: true,
      },
    },
    {
      path: '/tracker/*',
      component: () => import(/* webpackChunkName: "posts" */ '@/pages/Tracker.vue'),
      props: route => ({ startingPath: `/${route.params.pathMatch}` }),
      meta: {
        baseRoute: RouteNames.POSTS,
        hideMobileNav: true,
        hideFooter: true,
      },
    },
    {
      path: '/posts/:id',
      name: RouteNames.POST_DETAIL,
      component: () => import(/* webpackChunkName: "posts" */ '@/pages/PostsDetail.vue'),
      props: (route: any) => ({ id: route.params.id }),
      meta: {
        defaultFrom: 'posts.create',
        baseRoute: RouteNames.POSTS,
      },
    },
    {
      // This was used in some old email templates.
      path: '/p/create',
      redirect: { name: RouteNames.POST_CREATE },
    },
    {
      path: '/p/:id',
      redirect: { name: RouteNames.POST_DETAIL },
    },
    {
      // All www.iseechange.org links open in the native app if it's installed,
      // but this old share link would actually normally be handled by the API server:
      path: '/api/v1/posts/:id/social',
      redirect: { name: RouteNames.POST_DETAIL },
    },
    {
      path: '/topics',
      name: RouteNames.INVESTIGATIONS,
      component: () => import(/* webpackChunkName: "content" */ '@/pages/Investigations.vue'),
    },
    {
      path: '/investigations',
      redirect: { name: RouteNames.INVESTIGATIONS },
    },
    {
      path: '/topics/:id',
      component: () => import(/* webpackChunkName: "content" */ '@/pages/InvestigationsDetail.vue'),
      props: (route: any) => ({ id: route.params.id }),
      children: [
        {
          path: '/',
          name: RouteNames.INVESTIGATION_DESCRIPTION,
          component: () => import(/* webpackChunkName: "content" */ '@/pages/InvestigationsDetail/Description.vue'),
          meta: { baseRoute: RouteNames.INVESTIGATIONS },
        },
        {
          path: 'posts',
          name: RouteNames.INVESTIGATION_POSTS,
          component: () => import(/* webpackChunkName: "content" */ '@/pages/InvestigationsDetail/Posts.vue'),
          meta: { baseRoute: RouteNames.INVESTIGATIONS, mustBeSignedIn: true },
        },
        // {
        //   path: 'map',
        //   name: RouteNames.INVESTIGATION_MAP,
        //   component: () => import(/* webpackChunkName: "content" */ '@/pages/InvestigationsDetail/Map.vue'),
        //   meta: { baseRoute: RouteNames.INVESTIGATIONS },
        // },
      ],
    },
    {
      path: '/investigations/:id',
      redirect: { name: RouteNames.INVESTIGATION_DESCRIPTION },
    },
    {
      path: '/actions',
      name: RouteNames.ACTIONS,
      component: () => import(/* webpackChunkName: "content" */ '@/pages/Actions/Index.vue'),
      meta: { mustBeSignedIn: true },
    },
    {
      path: '/users/:id',
      name: RouteNames.USER_DETAIL,
      component: () => import(/* webpackChunkName: "content" */ '@/pages/UserDetail.vue'),
      props: (route: any) => ({ userId: route.params.id }),
      meta: { baseRoute: RouteNames.POSTS }, // There isn't really an index of users.
    },
    {
      path: '/u/:id',
      redirect: { name: RouteNames.USER_DETAIL },
    },
    {
      path: '/account',
      name: RouteNames.ACCOUNT,
      component: () => import(/* webpackChunkName: "account" */ '@/pages/Account/Index.vue'),
      meta: { mustBeSignedIn: true },
      children: [
        {
          path: 'profile',
          name: RouteNames.ACCOUNT_PROFILE,
          component: () => import(/* webpackChunkName: "account" */ '@/pages/Account/ProfileSettings.vue'),
          meta: { mustBeSignedIn: true },
        },
        {
          path: 'comments',
          name: RouteNames.ACCOUNT_COMMENTS,
          component: () => import(/* webpackChunkName: "account" */ '@/pages/Account/Comments.vue'),
          meta: { mustBeSignedIn: true },
        },
        {
          path: 'sensors',
          name: RouteNames.ACCOUNT_SENSORS,
          component: () => import(/* webpackChunkName: "account" */ '@/pages/Account/Sensors.vue'),
          meta: { mustBeSignedIn: true },
        },
        {
          path: 'notifications',
          name: RouteNames.ACCOUNT_NOTIFICATIONS,
          component: () => import(/* webpackChunkName: "account" */ '@/pages/Account/Notifications.vue'),
          meta: { mustBeSignedIn: true },
        },
        {
          path: 'integration',
          name: RouteNames.ACCOUNT_INTEGRATIONS,
          redirect: { name: RouteNames.ACCOUNT_SENSORS },
        },
        {
          path: 'login',
          name: RouteNames.ACCOUNT_LOGIN_DETAILS,
          component: () => import(/* webpackChunkName: "account" */ '@/pages/Account/LoginDetails.vue'),
          meta: { mustBeSignedIn: true },
        },
        {
          path: 'delete',
          name: RouteNames.ACCOUNT_DELETE,
          component: () => import(/* webpackChunkName: "account" */ '@/pages/Account/Delete.vue'),
          meta: { mustBeSignedIn: true },
        },
      ]
    },
    {
      name: 'join-user-group',
      path: '/join-group/:userGroupId',
      props: route => ({ userGroupId: route.params.userGroupId, inviteToken: route.hash.slice(1) }),
      component: () => import(/* webpackChunkName: "account" */ '@/pages/Account/JoinUserGroup.vue'),
      meta: { mustBeSignedIn: true },
    },
    {
      path: '/unsubscribe',
      redirect: { name: RouteNames.ACCOUNT_NOTIFICATIONS },
    },
    {
      name: 'project',
      path: '/projects/:organizationSlug/:projectSlug',
      props: route => ({ organizationSlug: route.params.organizationSlug, projectSlug: route.params.projectSlug }),
      component: () => import(/* webpackChunkName: "organization" */ '@/pages/LocalProjects/Detail/index.vue'),
      meta: { baseRoute: 'projects-index', preventInternalScroll: true },
    },
    {
      name: 'projects-index',
      path: '/projects',
      component: () => import(/* webpackChunkName: "organization" */ '@/pages/LocalProjects/Index.vue'),
    },
    {
      // A shortcut for single-project organizations:
      path: '/projects/:organizationSlug',
      async beforeEnter(to, from, next) {
        const projectsResponse = await store.state.apiClient.get(`/local-projects?clientGroup.slug=${to.params.organizationSlug}`);
        // Always points to the same project.
        const sortedByCreationDate = orderBy(projectsResponse.data.localProjects, 'createdAt');
        const firstProject = sortedByCreationDate[0];

        if (firstProject) {
          next({
            name: 'project',
            params: {
              organizationSlug: firstProject.clientGroup.slug,
              projectSlug: firstProject.projectDetails.slug,
            },
          });
        } else {
          // Kind of a hack, this will lead to a 404:
          next({
            name: RouteNames.CMS_CONTENT_PAGE,
            params: {
              collection: 'projects',
              page: to.params.organizationSlug
            },
          });
        }
      },
    },
    {
      name: 'project-welcome',
      path: '/welcome/:organizationSlug/:projectSlug',
      component: () => import(/* webpackChunkName: "organization" */ '@/pages/LocalProjects/Welcome.vue'),
      meta: {
        hideMainNavWithNoUser: true,
      },
    },
    {
      // A shortcut for single-project organizations:
      path: '/welcome/:organizationSlug',
      async beforeEnter(to, from, next) {
        const projectsResponse = await store.state.apiClient.get(`/local-projects?clientGroup.slug=${to.params.organizationSlug}`);
        // Always points to the same project.
        const sortedByCreationDate = orderBy(projectsResponse.data.localProjects, 'createdAt');
        const firstProject = sortedByCreationDate[0];

        if (firstProject) {
          next({
            name: 'project-welcome',
            params: {
              organizationSlug: firstProject.clientGroup.slug,
              projectSlug: firstProject.projectDetails.slug,
            },
          });
        } else {
          // Kind of a hack, this will lead to a 404:
          next({
            name: RouteNames.CMS_CONTENT_PAGE,
            params: {
              collection: 'projects',
              page: to.params.organizationSlug
            },
          });
        }
      },
    },

    {
      path: '/admin/style-guide',
      name: 'style-guide',
      component: () => import(/* webpackChunkName: "admin" */ '@/pages/StyleGuide/index.vue'),
    },
    // Fall through to CMS content (which also handles "not found")
    {
      path: '/:collection?/:page?',
      name: RouteNames.CMS_CONTENT_PAGE,
      component: () => import(/* webpackChunkName: "content" */ '@/pages/CmsContentPage.vue'),
      props: (route) => ({ collectionUid: route.params.collection, pageUid: route.params.page }),
    },
  ],
});


router.beforeEach(async (to, from, next) => {
  redirectIfSessionRequired(to);

  if (to.query.authorship_token) {
    setTimeout(async () => {
      await store.state.account.currentSessionCheck;
      if (store.state.account.currentUser) {

        const response = await store.state.apiClient.get(`/me?claim_observation=${to.query.authorship_token}`);
        if (response.statusText === 'OK') {
          router.replace({
            path: router.currentRoute.path,
            query: { ...router.currentRoute.query, authorship_token: undefined },
            hash: router.currentRoute.hash,
          });
        }
      }
    });
  }

  if (typeof to.query.lang === 'string' && i18n.availableLocales.includes(to.query.lang)) {
    i18n.locale = to.query.lang;
    localStorage.userLang = to.query.lang;
  }

  if (to.query.partner) {
    const partner = String(to.query.partner);
    store.dispatch('setPartner', partner);
  }

  if (to.query.ref) {
    const source = String(to.query.ref);
    trackReferringSource(source);
  }

  if (from.name) {
    store.dispatch('changeRoute', { to, from });
  }

  const ENV_OVERRIDE_KEY = 'dangerously-override-environment';

  const addEnvOverride = from.query[ENV_OVERRIDE_KEY] !== undefined &&
    to.query[ENV_OVERRIDE_KEY] !== from.query[ENV_OVERRIDE_KEY];

  if (addEnvOverride) {
    next({
      ...to,
      name: to.name ?? undefined, // Make TypeScript happy
      query: {
        ...to.query,
        [ENV_OVERRIDE_KEY]: from.query[ENV_OVERRIDE_KEY],
      },
    });
    return;
  }

  next();
});

router.afterEach((to) => {
  trackPageView(to);
  // If a non-nav link was clicked, it'll still be focused, so don't move the focus away.
  const shouldRefocus = !document.activeElement || document.activeElement.closest('nav, [role="navigation"]');
  if (shouldRefocus) {
    document.querySelector<HTMLElement>('#main-content-focus-point')?.focus();
  }
});

export default router;
