import { updateAuthToken } from '@/api-client';
import router from '@/router';
import RouteNames from '@/router/names';
import { VuexActionResult } from '@/store/util';
import { trackAccountCreation, trackPresence, trackSignIn, trackSignOut } from '@/tracking';
import { AccountState, Activity, Comment, CurrentUser, NewUser, Notification, Post, RootState, User } from '@/types';
import * as Sentry from '@sentry/browser';
import { checkAuth, signOut } from 'isc-shared/auth';
import get from 'lodash/get';
import { Module } from 'vuex';

interface AggregateActivitiesParams {
    activities: Activity[];
    users: User[];
    comments: Comment[];
    posts: Post[];
    notifications: Notification[];
}

function aggregateActivities(params: AggregateActivitiesParams) {
    const { activities, users, comments, posts, notifications } = params;
    const returnDataBundle = [];
    for (let i = 0; i < activities.length; ++i) {
        const activity = activities[i];
        activity.userObj = users.find((u: any) => u.id === activity.user);
        activity.actorObj = users.find((u: any) => u.id === activity.actor);
        activity.postObj = posts.find((p: any) => p.id === activity.postId);

        if (activity.commentId) {
            activity.commentObj = comments.find((c: Comment) => c.id === activity.commentId);
        }

        if (activity.notification) {
            activity.notificationObj = notifications.find((n: any) => n.id === activity.notification);
        }

        returnDataBundle.push(activity);
    }
    return returnDataBundle;
}

const AccountStore: Module<AccountState, RootState> = {
    state: {
        currentSessionCheck: null,
        visitorEmail: '',
        currentUser: null,
        autoAuthStatus: null,
        userActivities: [],
        userDevices: [],
    },
    mutations: {
        SET_VISITOR_EMAIL(state, email: string) {
            state.visitorEmail = email;
        },
        SET_CURRENT_USER(state, user: CurrentUser) {
            if (state.currentUser && user && state.currentUser.id === user.id) {
                // Lots of components react to this changing, so don't change the object if it's not nessesary.
            } else {
                state.currentUser = user;
            }
            state.visitorEmail = ''; // reset visitor email because we have a user now
            Sentry.configureScope(scope => {
                if (user) {
                    scope.setUser({ email: user.email });
                } else {
                    scope.setUser(null);
                }
            });
        },
        SET_SESSION_CHECK(state, promise) {
            // Don't reject just because nobody's initially signed in.
            state.currentSessionCheck = promise.catch(() => null);
        },
        AUTO_AUTH_FAILURE(state) {
            state.autoAuthStatus = false;
        },
        AUTO_AUTH_SUCCESS(state) {
            state.autoAuthStatus = true;
        },
        SET_USER_ACTIVITY(state, activities: any[]) {
            state.userActivities = activities;
        },
        SET_DEVICES(state, devices: any[]) {
            state.userDevices = devices;
        },
        UPDATED_USER_DETAILS(state, data) {
            if (state.currentUser) {
                Object.assign(state.currentUser, data);
            }
        },
    },
    getters: {
        // Hard-code this for Miami right now.
        coveredByMiamiA11y(state, getters, rootState): boolean {
            return rootState.partner === 'miami-flooding' || rootState.investigations.items.find(investigation => {
                return investigation.regionLabel?.toLowerCase().includes('miami') && investigation.slug === 'flooding';
            }) !== undefined;
        },
        accountCreatedRecently(state): boolean {
            const ONE_MONTH = 1000 * 60 * 60 * 24 * 30.33;
            const RECENTLY = ONE_MONTH * 2;
            return state.currentUser ? new Date(state.currentUser.createdAt) > new Date(Date.now() - RECENTLY) : false;
        },
        // Should the Actions page navigation link have an alert dot?
        hasNewActionsPageContent(state, getters): boolean {
            const lastViewed = state.currentUser?.userSettings?.lastViewedActionsPage;
            if (lastViewed !== undefined) {
                const lastUpdated = new Date('2021-06-01T00:00:00Z');
                return new Date(lastViewed) < lastUpdated;
            } else {
                return getters.accountCreatedRecently;
            }
        },
    },
    actions: {
        async checkIfSignedIn(context, { firstTime = false } = {}): Promise<VuexActionResult> {
            const userIdWas = context.state.currentUser?.id;
            const NOT_SIGNED_IN_MESSAGE = 'Not signed in';

            let resolveCurrentSessionCheck: (...args: unknown[]) => void = () => {};
            const currentSessionCheck = new Promise(resolve => resolveCurrentSessionCheck = resolve);
            context.commit('SET_SESSION_CHECK', currentSessionCheck);

            try {
                const { data } = await context.rootGetters.apiClient.get('/me', {
                    headers: {
                        'Cache-Control': 'no-cache',
                    },
                });
                context.commit('SET_CURRENT_USER', data);
                context.commit('AUTO_AUTH_SUCCESS');
                trackPresence(data);
                return new VuexActionResult({ data });
            } catch (error) {
                // Then we'll try the Identity service.
                try {
                    void error;
                    const user: CurrentUser | null = await checkAuth();
                    if (!user) throw new Error(NOT_SIGNED_IN_MESSAGE);
                    // TODO: The front and back ends don't quite agree here.
                    const fleshedOutUser = {
                        ...user,
                        lng: user.lng ?? NaN,
                        lat: user.lat ?? NaN,
                        addressComponents: { ...user?.addressComponents },
                    };
                    context.commit('SET_CURRENT_USER', fleshedOutUser);
                    context.commit('AUTO_AUTH_SUCCESS');
                    trackPresence(fleshedOutUser);
                    return new VuexActionResult({ data: fleshedOutUser });
                } catch (error) {
                    if (!(error instanceof Error) || error.message !== NOT_SIGNED_IN_MESSAGE) {
                        console.error(error);
                    }
                    context.commit('SET_CURRENT_USER', null);
                    context.commit('AUTO_AUTH_FAILURE');
                    return new VuexActionResult({ error });
                }
            } finally {
                resolveCurrentSessionCheck();
                const userChanged = context.state.currentUser?.id !== userIdWas;
                if (userChanged || firstTime) {
                    context.dispatch('fetchInvestigations');
                }
            }
        },
        async accountForgotPassword(context, email: string): Promise<VuexActionResult> {
            try {
                const { data } = await context.rootGetters.apiClient.post('/reset-password-email', {
                    email,
                });
                if (data.success) {
                    context.dispatch('alertUser', {
                        type: 'info',
                        message: `We've sent instructions to ${email}`,
                    });
                }
                return new VuexActionResult({ data });
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    context.dispatch('alertUser', {
                        type: 'error',
                        message: "That email address wasn't found in our system",
                    });
                } else {
                    context.dispatch('alertUser', {
                        type: 'error',
                        message: "We were unable to reset this password, please try again later",
                    });
                }
                return new VuexActionResult({ error })
            }
        },
        async accountLogin(context, params: { email: string, password: string, fromRegistration?: boolean }): Promise<VuexActionResult> {
            try {
                const { data } = await context.rootGetters.apiClient.post('/auths/login', {
                    grant_type: 'password',
                    username: params.email,
                    password: params.password,
                });
                const user: CurrentUser = data.user;

                // Sometimes this is null in the database, let's work around that.
                user.addressComponents = { ...user.addressComponents };

                updateAuthToken(data.access_token, new Date(Date.now() + data.expires_in));
                context.commit('SET_CURRENT_USER', user);

                if (!params.fromRegistration) {
                    trackSignIn(user);
                }
                return new VuexActionResult({ data });
            } catch (error) {
                return new VuexActionResult({ error });
            }
        },
        async accountSocialLogin(context, params) {
            try {
                if (!params.provider) {
                    throw new Error();
                }
                const { data } = await context.rootGetters.apiClient.post(`/auths/${params.provider}`, {
                    access_token: params.accessToken,
                    id_token: params.idToken,
                    firstName: params.firstName,
                    lastName: params.lastName,
                });
                const user: CurrentUser = data.user;

                updateAuthToken(data.access_token, new Date(Date.now() + data.expires_in));
                context.commit('SET_CURRENT_USER', user);

                if (Date.now() - new Date(user.createdAt).valueOf() <= 60_000) {
                    trackAccountCreation(user);
                }

                trackSignIn(user, { service: params.provider as string });

                return new VuexActionResult({ data });
            } catch (error) {
                return new VuexActionResult({ error });
            }
        },
        async accountUpdatePassword(context, password: string): Promise<VuexActionResult> {
            try {
                const { data } = await context.rootGetters.apiClient.post('/me/update-password', {
                    password,
                });
                return new VuexActionResult({ data });
            } catch (error) {
                return new VuexActionResult({ error });
            }
        },
        async accountLogOut(context): Promise<VuexActionResult> {
            try {
                await context.rootGetters.apiClient.post('/auths/logout').catch(() => {});
                console.log('Clearing');
                context.commit('SET_CURRENT_USER', null);
                await updateAuthToken(null);
                trackSignOut();
                await signOut();
                if (router.currentRoute.meta?.mustBeSignedIn) {
                    router.push({ name: RouteNames.HOME });
                }
                return new VuexActionResult({});
            } catch (error) {
                context.dispatch('alertUser', {
                    type: 'error',
                    message: 'There was a problem signing out. Please reload the page and try again.',
                });
                return new VuexActionResult({ error });
            }
        },
        async accountResetPassword(context, params: { password: string, email: string, token: string}): Promise<VuexActionResult> {
            try {
                const { data } = await context.rootGetters.apiClient.post('/change-password', params);
                if (data.success) {
                    context.dispatch('alertUser', {
                        type: 'info',
                        message: 'Your password has been changed',
                    });
                }
                return new VuexActionResult({ data });
            } catch (error) {
                context.dispatch('alertUser', {
                    type: 'error',
                    message: 'Unable to create new password',
                });
                return new VuexActionResult({ error });
            }
        },
        async checkEmailValidity(context, email: string): Promise<VuexActionResult> {
            try {
                const { data } = await context.rootGetters.apiClient.post('/check-email', {
                    email,
                });
                return new VuexActionResult({ data });
            } catch (error) {
                return new VuexActionResult({ error });
            }
        },
        async accountRegister(context, { clientGroupInviteToken, ...newUser }: NewUser & { clientGroupInviteToken: string | null }): Promise<VuexActionResult> {
            try {
                const endpoint = clientGroupInviteToken ? `/users?client_group_registration_token=${encodeURIComponent(clientGroupInviteToken)}` : '/users';
                const { data } = await context.rootGetters.apiClient.post(endpoint, newUser);
                const user = data.users; // `users` is a single object.
                trackAccountCreation(user);
                await context.dispatch('accountLogin', {
                    email: newUser.email,
                    password: newUser.password,
                    fromRegistration: true,
                });
                return new VuexActionResult({ data });
            } catch (error) {
                return new VuexActionResult({ error });
            }
        },
        async fetchUser(context, params: { id: string }): Promise<VuexActionResult> {
            if (context.state.currentUser && params.id === context.state.currentUser.id) {
                return new VuexActionResult({ data: context.state.currentUser });
            }
            try {
                const { data } = await context.rootGetters.apiClient.get(`/users/${params.id}`);
                const user: User = data.users[0];
                return new VuexActionResult({ data: user });
            } catch (error) {
                context.dispatch('alertUser', { type: 'error', message: 'Could not fetch user' });
                return new VuexActionResult({ error });
            }
        },
        async fetchDevices(context): Promise<VuexActionResult> {
            const id = get(context.state.currentUser, 'id', null);
            try {
                const { data } = await context.rootGetters.apiClient.get(`/users/${id}/devices`);
                context.commit('SET_DEVICES', data);
                return new VuexActionResult({ data });
            } catch (error) {
                return new VuexActionResult({ error });
            }
        },
        async fetchUserActivity(context, params = {}): Promise<VuexActionResult> {
            let queryparams = '';
            if (context.state.currentUser) {
                queryparams = `?page=${params.perPage || 1}&limit=20&sort=createdAt+DESC&actor[!]=${context.state.currentUser.id}&action[]=created-nearby-post&action[]=liked-post&action[]=commented-on-post&action[]=created-post`;
            }
            try {
                const { data } = await context.rootGetters.apiClient.get(`/activities/${queryparams}`);
                context.commit('SET_USER_ACTIVITY', aggregateActivities(data));
                return new VuexActionResult({ data });
            } catch (error) {
                context.dispatch('alertUser', { type: 'error', message: 'Could not fetch activity' });
                return new VuexActionResult({ error });
            }
        },
        async updateUserDetails(context, settingsAndSuccessMessage: Partial<CurrentUser> & { successMessage?: string | false }): Promise<VuexActionResult> {
            try {
                const { successMessage, ...settings } = settingsAndSuccessMessage;
                const { data } = await context.rootGetters.apiClient.put('/me', settings);
                context.commit('UPDATED_USER_DETAILS', data.users[0]);
                context.dispatch('fetchInvestigations');
                if ('lat' in settings || 'lng' in settings) {
                    await context.dispatch('updateSettings', { historicalWeatherPromptDismissed: '' });
                    await context.dispatch('updateUserHomeSightingsQuery', data.users[0]);
                }
                if (successMessage !== false) {
                    context.dispatch('alertUser', { type: 'info', message: successMessage ?? 'Settings updated'});
                }
                return new VuexActionResult({ data });
            } catch (error) {
                context.dispatch('alertUser', { type: 'error', message: 'Could not update settings' });
                return new VuexActionResult({ error });
            }
        },
        async updateUserHomeSightingsQuery(context, { lng, lat, userSettings }: CurrentUser) {
            const now = new Date();
            const sixMonthsAgo = new Date(now);
            sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
            const { data: posts } = await context.dispatch('fetchPosts', {
                lngLat: [lng, lat],
                distance: '20mi',
                limit: 5,
                fromDate: sixMonthsAgo.toISOString(),
                toDate: now.toISOString(),
                ignoreErrors: true,
            });
            const enoughRecentLocalSightings = posts?.length === 5;
            const homeSightingsQuery = JSON.stringify({
                // Decimal places should match the preset format from PostsFilters.
                near: [
                    lng.toFixed(4),
                    lat.toFixed(4),
                    userSettings?.preferredSystemOfMeasure === 'METRIC' ? '10km' : '20mi',
                ].join(','),
            });
            // Pre-set the sightings query to the home location if there are enough recent posts, otherwise default to "World".
            const sightingsQuery = enoughRecentLocalSightings ? homeSightingsQuery : '';
            await context.dispatch('updateSettings', { homeSightingsQuery, sightingsQuery });
        },
        async updateAvatar(context, params: { file: File, successMessage?: string | false }): Promise<VuexActionResult> {
            try {
                const formData = new FormData();
                formData.append('file', params.file);
                const { data: avatar } = await context.rootGetters.apiClient.post(
                    '/uploads/avatar',
                    formData,
                    { headers: { 'Content-Type': 'multipart/form-data' } },
                );
                await context.dispatch('updateUserDetails', {
                    avatar,
                    successMessage: params.successMessage ?? 'Profile image updated',
                });
                // context.dispatch('alertUser', { type: 'info', message: 'Photo updated' });
                return new VuexActionResult({ data: avatar });
            } catch (error) {
                context.dispatch('alertUser', { type: 'error', message: 'Could not update avatar' });
                return new VuexActionResult({ error });
            }
        },
        async updateSettings(context, changes: { [key: string]: any }) {
            if (!context.state.currentUser) {
                throw new Error('Tried to update settings without no currentUser set');
            } else {
                context.commit('UPDATED_USER_DETAILS', {
                    ...context.state.currentUser,
                    userSettings: {
                        ...context.state.currentUser.userSettings,
                        ...changes
                    }
                });

                const errors = [];

                for (const [key, value] of Object.entries(changes)) {
                   try {
                       await context.rootGetters.apiClient.put(`/user-settings/${key}`, { value });
                   } catch(error) {
                       errors.push(error);
                   }
                }

                if (errors.length !== 0) {
                    throw new Error(errors.map(error => error.message).join('\n'));
                }
            }
        },

        async deleteCurrentUserAccount(context, { andPosts, onProgress }: { andPosts?: boolean, onProgress?: Function }) {
            if (andPosts) {
                await context.dispatch('deleteCurrentUserPosts', { onProgress });
            }

            await context.dispatch('updateUserDetails', {
                email: `DELETED_${new Date().toISOString().split(/\D+/).join('_')}@example.com`,
                firstName: '(Deleted)',
                lastName: '(Deleted)',
                description: '(Deleted)',
                lng: -1, // TODO: `0` doesn't persist.
                lat: -1, // TODO: `0` doesn't persist.
                addressComponents: {},
                successMessage: false,
            });

            const randomString = new Array(3).fill(null).map(() => Math.random().toString(36).split('.')[1]).join('');
            await context.dispatch('accountUpdatePassword', randomString);

            const currentUserId = context.state.currentUser!.id;
            await context.rootState.apiClient.put(`/users/${currentUserId}`, {
                deletedAt: new Date(),
            });

            await context.dispatch('accountLogOut');
        },

        async deleteCurrentUserPosts(context, { onProgress }: { onProgress?: Function }) {
            const currentUserId = context.state.currentUser!.id;

            let hasPostsRemaining = true;
            while (hasPostsRemaining) {
                const { data } = await context.rootState.apiClient.get(`/posts?user=${currentUserId}&limit=50`);
                if (data.posts.length === 0) {
                    hasPostsRemaining = false;
                } else {
                    for (const post of data.posts) {
                        await new Promise(resolve => setTimeout(resolve, 500));
                        await context.dispatch('deletePost', { id: post.id, noAlert: true });
                        onProgress?.(post);
                    }
                }
            }
        },
    },
};


export default AccountStore;
