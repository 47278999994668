enum RouteNames {
  ACCOUNT = 'account.index',
  ACCOUNT_COMMENTS = 'account.comments',
  ACCOUNT_DELETE = 'account.delete',
  ACCOUNT_INTEGRATIONS = 'account.integrations',
  ACCOUNT_NOTIFICATIONS = 'account.notifications',
  ACCOUNT_LOGIN_DETAILS = 'account.login-details',
  ACCOUNT_PROFILE = 'account.profile',
  ACCOUNT_SENSORS = 'account.sensors',
  ACTIONS = 'actions',
  FORGOT_PASSWORD = 'forgotpassword',
  GENERIC_PAGE = 'generic_page', // NOTE: from prismic
  CAMPAIGN_LANDING_PAGE = 'campaign-landing-page',
  CMS_CONTENT_PAGE = 'cms_content_page',
  HOME = 'home',
  LANDING_NEW_ORLEANS = 'landing.neworleans',
  INVESTIGATION_DESCRIPTION = 'investigations.detail.description',
  INVESTIGATION_MAP = 'investigations.detail.map',
  INVESTIGATION_POSTS = 'investigations.detail.posts',
  INVESTIGATIONS = 'investigations',
  LOGIN = 'login',
  PARTNER_LANDING_PAGE = 'partner-landing-page',
  PEOPLE = 'people',
  POST_CREATE = 'posts.create',
  POST_DETAIL = 'posts.detail',
  POSTS = 'posts',
  REGISTER = 'register',
  RESET_PASSWORD = 'resetpassword',
  SENSORS = 'sensors',
  STORIES = 'stories',
  USER_DETAIL = 'user.detail',
}

export default RouteNames;
